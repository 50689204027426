import React from "react"

import styles from "./list-features-item.module.css"

export default function ListFeaturesItem({
  classVariant,
  title,
  text,
  image,
  id,
}) {
  return (
    <div
      id={id}
      className={`${styles.listFeaturesItem} ${
        classVariant ? styles[classVariant] : ""
      }`}
    >
      <img
        src={image}
        alt={`Smartpass ${title}`}
        className={styles.listFeaturesItemImg}
      />
      <h2 className={styles.listFeaturesItemTitle}>{title}</h2>
      <p className={styles.listFeaturesItemText}>{text}</p>
    </div>
  )
}
