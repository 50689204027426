import React from "react"

import styles from "./title.module.css"

export default function Title({ children, classVariant, id }) {
  return (
    <h2
      id={id}
      className={`${styles.title} ${classVariant ? styles[classVariant] : ""}`}
    >
      {children}
    </h2>
  )
}
