import { Trans, useTranslation } from "react-i18next"

import Container from "../components/container/container"
import Content from "../components/content/content"
import FeatureIcon1 from "../images/discounts-small.svg"
import FeatureIcon2 from "../images/car.svg"
import FeatureIcon3 from "../images/heart.svg"
import FeatureIcon4 from "../images/key.svg"
import FeatureIcon5 from "../images/payment-methods.svg"
import FeatureIcon6 from "../images/receipt.svg"
import FeatureIcon7 from "../images/scan-qr.svg"
import FeatureIcon8 from "../images/discounts.svg"
import FeatureIcon9 from "../images/location.svg"
import FeatureInfo from "../components/feature-info/feature-info"
import Footer from "../components/footer/footer"
import Header from "../components/header/header"
import HeroHome from "../components/hero-home/hero-home"
import HeroHomeContent from "../components/hero-home-content/hero-home-content"
import HeroHomeCover from "../components/hero-home-cover/hero-home-cover"
import HeroHomeDivisor from "../components/hero-home-divisor/hero-home-divisor"
import ListFeatures from "../components/list-features/list-features"
import ListFeaturesItem from "../components/list-features-item/list-features-item"
import PaymentIcons from "../images/card-brands.svg"
import React from "react"
import SEO from "../components/seo/seo"
import Ticket from "../components/ticket/ticket"
import Title from "../components/title/title"
import Wrapper from "../components/wrapper/wrapper"
import { graphql } from "gatsby"
import i18next from "../i18n/config"
import BkgBlackOpacity from "../components/bkg-black-opacity/bkg-black-opacity"

export default function Home({ location, data }) {
  const { t } = useTranslation()
  const PageTitle = t("pages.home.title")
  const PageDescription = t("meta.description")
  return (
    <Wrapper>
      <SEO title={PageTitle} description={PageDescription} />
      <HeroHome>
        <BkgBlackOpacity />
        <Container>
          <Header classVariant="headerWithBanner" location={location} />
          <HeroHomeContent
            phoneImage={data.phone.childImageSharp.fluid}
            title={t("pages.home.hero.title")}
            text={t("pages.home.hero.subtitle")}
            downloadTitle={t("pages.home.hero.download")}
          />
        </Container>
        <HeroHomeCover background={data.heroBackground.childImageSharp.fluid} />
        <HeroHomeDivisor />
      </HeroHome>
      <Content classVariant="contentBenefit">
        <Container>
          <Title id="benefit-title" classVariant="titleDHero">
            {t("pages.home.benefits.title")}
          </Title>
          <ListFeatures>
            <ListFeaturesItem
              id="benefit-item-1"
              title={t("pages.home.benefits.items.discounts.title")}
              text={t("pages.home.benefits.items.discounts.text")}
              image={FeatureIcon1}
              classVariant="listFeaturesItemCenterXs"
            />
            <ListFeaturesItem
              id="benefit-item-2"
              title={t("pages.home.benefits.items.quick.title")}
              text={t("pages.home.benefits.items.quick.text")}
              image={FeatureIcon2}
              classVariant="listFeaturesItemCenterXs"
            />
            <ListFeaturesItem
              id="benefit-item-3"
              title={t("pages.home.benefits.items.easy.title")}
              text={t("pages.home.benefits.items.easy.text")}
              image={FeatureIcon3}
              classVariant="listFeaturesItemCenterXs"
            />
            <ListFeaturesItem
              id="benefit-item-4"
              title={t("pages.home.benefits.items.secure.title")}
              text={t("pages.home.benefits.items.secure.text")}
              image={FeatureIcon4}
              classVariant="listFeaturesItemCenterXs"
            />
            <ListFeaturesItem
              id="benefit-item-5"
              title={t("pages.home.benefits.items.flexible.title")}
              text={t("pages.home.benefits.items.flexible.text")}
              image={FeatureIcon5}
              classVariant="listFeaturesItemCenterXs"
            />
            <ListFeaturesItem
              id="benefit-item-6"
              title={t("pages.home.benefits.items.convenient.title")}
              text={t("pages.home.benefits.items.convenient.text")}
              image={FeatureIcon6}
              classVariant="listFeaturesItemCenterXs"
            />
          </ListFeatures>
        </Container>
      </Content>
      <Content classVariant="contentScanCode">
        <Container>
          <ListFeatures>
            <ListFeaturesItem
              id="scan-code-item"
              title={t("pages.home.scan-code.title")}
              text={t("pages.home.scan-code.text")}
              image={FeatureIcon7}
            />
          </ListFeatures>
        </Container>
        <Ticket />
      </Content>
      <Content classVariant="contentRebates">
        <Container>
          <FeatureInfo img={FeatureIcon8} classVariant="featuresInfoRebates">
            <Trans i18n={i18next} i18nKey="pages.home.rebates.text">
              {t("pages.home.rebates.text")}
            </Trans>
          </FeatureInfo>
        </Container>
      </Content>
      <Content
        classVariant="contentNear"
        background={data.nearBackground.childImageSharp.fluid}
      >
        <BkgBlackOpacity />
        <Container>
          <ListFeatures>
            <ListFeaturesItem
              id="near-item"
              title={t("pages.home.near.title")}
              text={t("pages.home.near.text")}
              image={FeatureIcon9}
              classVariant="listFeaturesItemSmallXs"
            />
          </ListFeatures>
        </Container>
      </Content>
      <Content classVariant="contentPayment">
        <Container>
          <FeatureInfo
            img={FeatureIcon5}
            classVariant="featuresInfoPayments"
            paymentIcons={PaymentIcons}
          >
            {t("pages.home.payment-methods.text")}
          </FeatureInfo>
        </Container>
      </Content>
      <Content
        classVariant="contentReviews"
        background={data.reviewsBackground.childImageSharp.fluid}
      >
        <BkgBlackOpacity />
        <Container>
          <Title classVariant="titleReview">
            {t("pages.home.reviews.title")}
          </Title>
        </Container>
      </Content>
      <Footer />
    </Wrapper>
  )
}
export const query = graphql`
  query IndexQuery {
    heroBackground: file(relativePath: { eq: "hero-photo.jpg" }) {
      childImageSharp {
        fluid(quality: 100, maxWidth: 3000) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    phone: file(relativePath: { eq: "phone-home-3.png" }) {
      childImageSharp {
        fluid(quality: 100, maxWidth: 1043) {
          ...GatsbyImageSharpFluid_noBase64
        }
      }
    }
    nearBackground: file(relativePath: { eq: "mid.jpg" }) {
      childImageSharp {
        fluid(quality: 100, maxWidth: 2048) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    reviewsBackground: file(relativePath: { eq: "fueling.jpg" }) {
      childImageSharp {
        fluid(quality: 100, maxWidth: 3000) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`
