import React from "react"

import styles from "./feature-info.module.css"

export default function FeatureInfo({
  children,
  classVariant,
  img,
  paymentIcons,
}) {
  return (
    <div
      className={`${styles.featuresInfo} ${
        classVariant ? styles[classVariant] : ""
      }`}
    >
      <img src={img} alt="Smartpass" className={styles.featuresInfoImg} />
      {paymentIcons ? (
        <p id="payment-methods-text" className={styles.featuresInfoText}>
          {children}
        </p>
      ) : (
        <p id="rebates-text" className={styles.featuresInfoText}>
          {children}
        </p>
      )}
      {paymentIcons && (
        <img
          src={paymentIcons}
          alt="Smartpass payments"
          className={styles.featuresInfoPaymentMethods}
        />
      )}
    </div>
  )
}
