import React, { useCallback, useState, useEffect, useRef } from "react"

import throttle from "lodash.throttle"

import styles from "./ticket.module.css"

export default function Ticket() {
  const ref = useRef()

  const [animated, setAnimated] = useState(false)

  const listenToScroll = useCallback(() => {
    if (ref.current.getBoundingClientRect().top) {
      if (ref.current.getBoundingClientRect().top < 400 && !animated) {
        setAnimated(true)
      }
    }
  }, [animated])

  // Use throttle for performance
  const listenToScrollThrottled = throttle(listenToScroll, 250)

  useEffect(() => {
    if (!animated) {
      window.addEventListener("scroll", listenToScrollThrottled)
    }

    return () => window.removeEventListener("scroll", listenToScrollThrottled)
  }, [animated, listenToScrollThrottled])

  return (
    <div
      className={`${styles.scanCodeTicket} ${
        animated ? styles.scanCodeTicketAnimated : ""
      }`}
      ref={ref}
    ></div>
  )
}
